*{
  box-sizing: border-box;
}

html{
  font-family: sans-serif;
  background: linear-gradient(90deg, hsla(210, 90%, 80%, 1) 0%, hsla(212, 93%, 49%, 1) 100%);
}

body{
  margin: 0;
}